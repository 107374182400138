import React from 'react'
import { BaseLayout } from '@layouts/base'
import { PurchaseApp } from '@components/purchase/PurchaseApp'
import '@styles/purchase.scss'
import { PageProps } from 'gatsby'

const PurchasePage: React.FC<PageProps> = () => {
  return (
    <BaseLayout title='Purchase FullCalendar Premium'>
      <PurchaseApp />
    </BaseLayout>
  )
}

export default PurchasePage
